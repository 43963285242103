import React from 'react';
import styled from 'styled-components';
import { useSiteMetadata } from '../../utils';

const Section = styled.section``;

const GoogleMap = styled.div`
  min-height: 25rem;
  height: 100%;
  iframe {
    width: 100%;
    min-height: 25rem;
    height: 100%;
    border: none;
  }
`;

const Section01 = () => {
  //
  const data = useSiteMetadata();

  const {
    contact: { googleMapEmbed },
  } = data;

  return (
    <Section>
      <GoogleMap className="photoframe">
        <iframe title="map" src={googleMapEmbed} />
      </GoogleMap>
    </Section>
  );
};

export default Section01;
