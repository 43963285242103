import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TextField, TextArea, FormField, Button } from '../../components/Elements';
import { titleCase, useSiteMetadata } from '../../utils';
import { Dialog } from '../../components/Dialog';
import { MapIcon } from '../../components/Svg/MapIcon';
import { PhoneIcon } from '../../components/Svg/PhoneIcon';
import { EmailIcon } from '../../components/Svg/EmailIcon';
import { PlaceIcon } from '../../components/Svg/PlaceIcon';
import { WhatsappIcon } from '../../components/Svg/WhatsappIcon';

const Section = styled.section`
  padding: 4rem 0 8rem;

  .container {
    max-width: 37.5rem;
    margin: 0 auto;
    padding: 0 1rem;
    display: grid;
    grid-row-gap: 4rem;
  }

  .checkbox-message {
    position: absolute;
    bottom: -1rem;
    left: 2rem;
    color: ${({ theme }) => theme.errorColor};
  }

  .checkbox-font-color {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .contact-info {
    padding: 2rem 1rem;
    svg {
      fill: ${({ theme }) => theme.onLightColorHighEmphasis};
    }
    border: 1px solid ${({ theme }) => theme.outlineColor};
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    background-color: ${({ theme }) => theme.lowEmphasisBackgroundColor};
  }

  .icon-title-block {
    margin-bottom: 1rem;
    position: relative;
    display: flex;
  }

  .title-container {
    margin-left: 1rem;
  }

  @media (min-width: 52rem) {
    .container {
      max-width: 70rem;
      grid-template-columns: 3fr 2fr;
      grid-column-gap: 3rem;
    }

    .contact-info {
      padding: 3rem 2rem;
    }
  }
`;

const Section01 = () => {
  //
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isFailDialogOpen, setIsFailDialogOpen] = useState(false);
  const [contacteeName, setContacteeName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
  }, []);

  const { register, handleSubmit, watch, errors, getValues, reset } = useForm();

  const data = useSiteMetadata();

  const {
    siteName,
    contact: {
      address: { streetAddress, addressLocality, postalCode },
      telephone,
      whatsapp,
      email: siteEmail,
      googleMap,
    },
    business: {
      customHours: {
        mon: { block1: monDinner },
        tueToSun: { block1: tueToSunLunch, block2: tueToSunDinner },
      },
    },
    socialMedia: { facebook },
  } = data;

  const submitForm = async () => {
    const { name, email, phone, message } = getValues();
    const formattedName = titleCase(name);
    const formattedEmail = email.toLowerCase();

    try {
      setContacteeName(formattedName);
      setIsSubmitting(true);
      const res = await fetch('https://wfeqweb793.execute-api.ap-southeast-1.amazonaws.com/prod/contactform', {
        method: 'POST',
        body: JSON.stringify({
          name: formattedName,
          email: formattedEmail,
          phone,
          message,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (res.status === 200) {
        setIsSuccessDialogOpen(true);
        reset();
      }
    } catch (error) {
      setIsFailDialogOpen(true);
    }
  };

  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    window.submitValidatedForm = (token) => {
      window.grecaptcha.reset();
      if (token) {
        submitForm();
      }
    };
  }

  return (
    <>
      <Section>
        <div className="container">
          <div>
            <form onSubmit={handleSubmit(() => window.grecaptcha.execute())}>
              <FormField>
                <TextField
                  name="name"
                  formRef={register({ required: 'Your name is required' })}
                  isFilled={Boolean(watch('name'))}
                  label="Name"
                  helpertext="Required *"
                  error={
                    errors.name && (
                      <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.name.message}</span>
                    )
                  }
                  modifiers={['filled']}
                />
              </FormField>
              <FormField>
                <TextField
                  name="email"
                  formRef={register({
                    required: 'Your email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please provide a valid email address',
                    },
                  })}
                  isFilled={Boolean(watch('email'))}
                  label="Email"
                  helpertext="Required *"
                  error={
                    errors.email && (
                      <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.email.message}</span>
                    )
                  }
                  modifiers={['filled']}
                />
              </FormField>
              <FormField>
                <TextField
                  type="tel"
                  name="phone"
                  formRef={register({
                    maxLength: 12,
                    pattern: { value: /^-?[0-9]\d*\.?\d*$/i, message: 'Please provide a valid phone number' },
                  })}
                  isFilled={Boolean(watch('phone'))}
                  label="Phone"
                  error={
                    errors.phone && (
                      <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.phone.message}</span>
                    )
                  }
                  modifiers={['filled']}
                />
              </FormField>
              <FormField>
                <TextArea
                  name="message"
                  formRef={register({
                    required: 'Your message is required',
                  })}
                  value={watch('message')}
                  label="Your Message"
                  error={
                    errors.message && (
                      <span className="roboto-text-sm roboto-text-sm--subtitle">{errors.message.message}</span>
                    )
                  }
                  modifiers={['filled']}
                />
              </FormField>
              <FormField>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agree"
                      inputRef={register({
                        required: (
                          <div className="roboto-text-sm roboto-text-sm--subtitle checkbox-message">
                            You must agree to our policy
                          </div>
                        ),
                      })}
                    />
                  }
                  label={
                    <>
                      <p className="roboto-text-base roboto-text-base--subtitle checkbox-font-color">
                        Agree to our{' '}
                        <a
                          className="link"
                          href="/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="label"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </>
                  }
                />
                {errors.agree && <span>{errors.agree.message}</span>}
              </FormField>

              <div
                className="g-recaptcha visually-hidden"
                data-sitekey={process.env.GATSBY_ReCAPTCHA_SITEKEY}
                data-callback="submitValidatedForm"
                data-size="invisible"
                data-formfield="captcha"
                data-badge="inline"
              />

              <Button type="submit" modifiers={['contained', 'full']} disabled={isSubmitting}>
                {isSubmitting ? 'Submitting' : 'Submit'}
              </Button>
            </form>
          </div>
          <div>
            <div className="contact-info">
              {/* <div className="icon-title-block">
                <div className="icon-container">
                  <WhatsappIcon />
                </div>
                <div className="title-container">
                  <a className="link" href={`https://wa.me/${whatsapp.replace(/\s+|-/g, '')}`}>
                    <div className="roboto-text-base roboto-text-base--subtitle nowrap">{whatsapp}</div>
                  </a>
                </div>
              </div> */}
              <div className="icon-title-block">
                <div className="icon-container">
                  <PhoneIcon />
                </div>
                <div className="title-container">
                  <a className="link" href={`tel:${telephone}`}>
                    <div className="roboto-text-base roboto-text-base--subtitle nowrap">{telephone}</div>
                  </a>
                </div>
              </div>
              <div className="icon-title-block">
                <div className="icon-container">
                  <EmailIcon />
                </div>
                <div className="title-container">
                  <a className="link" href={`mailto:${siteEmail}`}>
                    <div className="roboto-text-base roboto-text-base--subtitle">{siteEmail}</div>
                  </a>
                </div>
              </div>
              <div className="icon-title-block">
                <div className="icon-container">
                  <MapIcon />
                </div>
                <div className="title-container">
                  <div className="roboto-text-base roboto-text-base--subtitle">
                    {`${streetAddress},`}
                    <br />
                    {`${addressLocality} ${postalCode}`}
                  </div>
                </div>
              </div>
              <div className="icon-title-block map">
                <div className="icon-container">
                  <PlaceIcon />
                </div>
                <div className="title-container">
                  <a href={googleMap} target="_blank" rel="noopener noreferrer" className="link">
                    <div className="roboto-text-base roboto-text-base--subtitle">Find us on Google Map</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Dialog
        open={isSuccessDialogOpen}
        dialogTitle={`Dear ${contacteeName}`}
        dialogMessage={
          <>
            Thank you for your enquiry.
            <br />A member of our team will contact you soon.
          </>
        }
        dialogPrimaryActionTitle="Close"
        dialogPrimaryAction={() => setIsSuccessDialogOpen(false)}
        closeFunc={() => setIsSuccessDialogOpen(false)}
      />
      <Dialog
        open={isFailDialogOpen}
        dialogTitle="Something has gone wrong."
        dialogMessage="Please resubmit contact form."
        dialogPrimaryActionTitle="Close"
        dialogPrimaryAction={() => setIsFailDialogOpen(false)}
        closeFunc={() => setIsFailDialogOpen(false)}
      />
    </>
  );
};

export default Section01;
