import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';
import Section01 from '../page-sections/contact/Section01';
import Section02 from '../page-sections/contact/Section02';

const Contact = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO title={seo.title} description={seo.description} pathname={pathname} featureImage="og-contact.jpg" />
      <Section01 />
      <Section02 />
    </article>
  );
};

export default Contact;

Contact.propTypes = {
  location: PropTypes.object.isRequired,
};
